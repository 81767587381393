<template>
    <p class="text-danger overflow-hidden">
        <span
            v-if="isHtml"
            :class="[alignClass]"
            class="h-100 preserve-whitespace d-flex flex-column"
            v-html="displayAnswer"
        ></span>
        <span v-else>{{ displayAnswer }}</span>
    </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    props: {
        answer: {
            type: String,
            default: '',
        },
        isHtml: {
            type: Boolean,
            default: true,
        },
        answerType: {
            type: String,
            default: 'standard_lines',
        },
        questionIsMultiLine: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            showAnswerKey: 'document/showAnswerKey',
        }),
        displayAnswer(): string {
            return this.showAnswerKey ? this.answer : ''
        },
        alignClass(): string {
            if (this.answerType === 'single' && this.questionIsMultiLine) {
                return 'justify-content-end'
            }
            return ''
        },
    },
})
</script>
