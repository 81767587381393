<template>
    <div v-if="!item.hide" class="d-flex mb-0">
        <div
            v-if="item.numberable && item.numbering && item.numbering.number > 0"
            class="mr-2"
            v-html="
                $store.getters['document/format_number'](
                    (item.numbering && item.numbering.number) || 1,
                    item.numbering && item.numbering.format,
                )
            "
        ></div>
        <div class="flex-grow-1" style="overflow: hidden !important">
            <HandwritingItemPreview
                v-for="(hItem, itemIndex) in handwritingItems"
                :key="`preview-${hItem.id}-${itemIndex}`"
                :item="hItem"
                :cursive-font="cursiveFont"
                :data="item.data"
                :last-item="itemIndex === handwritingItems.length - 1"
                @optionItemClicked="handlePreviewClick(itemIndex)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HandwritingItemPreview from './HandwritingItemPreview.vue'
import { HandWritingInputItem } from 'resources/js/types/handwritinginputitem'

export default defineComponent({
    name: 'HandwritingPreview',
    components: {
        HandwritingItemPreview,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        handwritingItems(): HandWritingInputItem[] {
            return this.item.data.items
        },
        cursiveFont(): string {
            return this.item.data[`hw_font_${this.item.data.cursive_style}`]
        },
    },
    methods: {
        handlePreviewClick(itemIndex: number) {
            this.$emit('optionItemClicked', { ...this.item, ...{ clickedIndex: { index: itemIndex, clickCount: 0 } } })
        },
    },
})
</script>
