<template>
    <div v-if="!item.hide" class="mb-0 overflow-hidden">
        <div class="word-scramble" :class="['grid-' + item.data.number_of_columns]" :style="wordScrambleContainerStyle">
            <div
                v-for="(word, index) in item.data.terms"
                :key="index"
                class="item d-flex mb-0 w-100"
                :style="{
                    padding: `${(style.font_space - 1.5) * 0.5}em 0`,
                    paddingBottom: '0.875em',
                    minWidth: 0,
                    overflow: 'hidden',
                }"
            >
                <span
                    v-if="item.numbering && item.numbering.number > 0"
                    class="preview-number d-flex align-items-top"
                    :class="{ 'number-hide': item.numbering.hidden }"
                >
                    <NumberFormatIndex :index-info="getWordScrambleNumbering(index)" resizable />
                </span>
                <div
                    class="d-flex term w-100"
                    :class="{
                        'flex-column': linePosition === 'below' || item.data.type === WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
                    }"
                >
                    <template v-if="item.data.scramble_by_word && canSplitWord(word)">
                        <div class="d-flex" :style="scrambledWordStyle">
                            <div :class="`scrambled-word-${item.id}`">
                                <span v-for="(term, termIndex) in word.split(' ')" :key="termIndex" class="text-pre mr-1">
                                    {{ scramble(term, item.data.shuffle_seeds ? item.data.shuffle_seeds[index] : 1) }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="d-flex" :style="scrambledWordStyle">
                            <span class="text-pre" :class="`scrambled-word-${item.id}`">
                                {{ scramble(word, item.data.shuffle_seeds ? item.data.shuffle_seeds[index] : 1) }}
                            </span>
                        </div>
                    </template>
                    <div
                        v-if="item.data.type !== WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM"
                        class="answer-key"
                        :style="answerKeyStyle"
                    >
                        <span class="answer text-danger">
                            <span
                                class="text-pre"
                                :style="{
                                    opacity: showAnswerKey ? '1' : '0',
                                }"
                            >
                                {{ word ? word.trim() : '' }}
                            </span>
                            <span
                                class="answer-border"
                                :style="{
                                    width: `${item.data.line_length}px`,
                                    opacity: item.data.type === 2 ? 0 : 1,
                                }"
                            />
                        </span>
                    </div>
                    <div v-else class="choices ml-2 mt-2">
                        <div v-for="(option, optionIndex) in item.data.terms_choices" :key="`option-${optionIndex}`">
                            <div
                                v-if="option.term_index === index"
                                class="choice d-flex align-items-start"
                                :style="{
                                    lineHeight: style.font_space + 'em',
                                }"
                            >
                                <div class="d-flex align-items-center justify-content-center">
                                    <div
                                        class="correct-answer-marker rounded-circle d-flex align-items-start justify-cont ent-center"
                                        :class="{ 'border-danger': option.correct && showAnswerKey }"
                                    >
                                        <span
                                            v-if="
                                                multipleChoiceAnswerFormat === parseInt(noneFormatOption.value) &&
                                                option.correct &&
                                                showAnswerKey
                                            "
                                        >
                                            <b-icon-check variant="danger" />
                                        </span>
                                        <NumberFormatIndex
                                            v-else
                                            :index-info="{
                                                number: option.sub_index + 1,
                                                format: multipleChoiceAnswerFormat,
                                            }"
                                            resizable
                                        />
                                    </div>
                                </div>
                                <div class="p-0 d-flex align-items-center option-answer">
                                    <div style="word-break: break-word">{{ option.answer }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span v-if="item.data.definition" class="answer-line hint text-pre">
                {{ item.data.definition }}
            </span>
        </div>
        <div v-if="item.data.type === WORD_SCRAMBLE_TYPE_MULTIPLE" class="choices mt-2 mx-3">
            <div
                v-for="(option, index) in item.data.choices"
                :key="`option-${index}`"
                class="choice d-flex align-items-center"
            >
                <div
                    class="d-flex justify-content-center align-items-center mr-2"
                    style="height: 30px; width: 30px"
                    :class="{
                        'border border-danger rounded-circle': option.correct && showAnswerKey,
                    }"
                >
                    <span class="number" v-text="indexToLetter(index) + '. '"></span>
                </div>
                <div class="text-pre">{{ option.answer }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters } from 'vuex'
import {
    WORD_SCRAMBLE_TEXT_CASE_DEFAULT,
    WORD_SCRAMBLE_TYPE_LINES,
    WORD_SCRAMBLE_TYPE_MULTIPLE,
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
} from '../../store/helpers/documentHelpers'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'
import { WordScrambleData, WordScrambleStyle } from '../../types/word-scramble'
import { Item, Numbering } from '../../types/item'
import Chance from 'chance'
import { EventBus } from '../../common/EventBus'
import { defaultLetteringFormat, defaultNumberingFormat, noneFormatOption } from '../../objects/NumberingFormat'
import { FormatSubOption } from '../../stories/types/FormatOption'

interface Data {
    WORD_SCRAMBLE_TYPE_LINES: number
    WORD_SCRAMBLE_TYPE_MULTIPLE: number
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM: number
    defaultNumberingFormat: FormatSubOption
    defaultLetteringFormat: FormatSubOption
    noneFormatOption: FormatSubOption
    WORD_SCRAMBLE_TEXT_CASE_DEFAULT: string
}

export default defineComponent({
    name: 'WordScramblePreview',
    components: { NumberFormatIndex },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object as PropType<Item<WordScrambleData, WordScrambleStyle>>,
            required: true,
        },
    },
    data(): Data {
        return {
            WORD_SCRAMBLE_TYPE_LINES,
            WORD_SCRAMBLE_TYPE_MULTIPLE,
            WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
            defaultNumberingFormat,
            defaultLetteringFormat,
            noneFormatOption,
            WORD_SCRAMBLE_TEXT_CASE_DEFAULT,
        }
    },
    computed: {
        ...mapGetters({
            canBeShuffled: 'document/canBeShuffled',
            style: 'document/documentStyle',
            currentWidget: 'document/currentWidget',
            showAnswerKey: 'document/showAnswerKey',
        }),
        linePosition(): string {
            if (![WORD_SCRAMBLE_TYPE_LINES, WORD_SCRAMBLE_TYPE_MULTIPLE].includes(this.item.data.type)) return 'blank'
            if (this.item.data.type !== WORD_SCRAMBLE_TYPE_LINES) return 'after'

            return this.item.data.line_position
        },
        longestWordWidth(): string {
            const words = document.querySelectorAll(`.scrambled-word-${this.item.id}`)
            let maxWidth = 0

            words.forEach((word) => {
                const wordWidth = word.getBoundingClientRect().width

                if (wordWidth > maxWidth) {
                    maxWidth = wordWidth
                }
            })

            return maxWidth === 0 ? 'auto' : `${maxWidth}px`
        },
        answerKeyWidthAvailable(): number {
            const answerKeyContainer = document.querySelectorAll('.answer-key')[0]

            return answerKeyContainer ? answerKeyContainer.getBoundingClientRect().width : 0
        },
        scrambledWordStyle(): StyleValue {
            return {
                width: `${this.longestWordWidth} !important`,
                textTransform: this.item.data.text_case || WORD_SCRAMBLE_TEXT_CASE_DEFAULT,
                fontSize: `${this.style?.font_size}px !important`,
            }
        },
        answerKeyStyle(): StyleValue {
            if (this.linePosition === 'after' || this.item.data.type === 2) {
                return {
                    marginLeft: '8px',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    flex: 1,
                }
            }

            return {
                flexBasis: '100%',
                width: '100%',
                display: 'block',
                overflow: 'hidden',
                marginTop: '4px',
                alignItems: 'center',
            }
        },
        wordScrambleContainerStyle(): StyleValue {
            return {
                borderWidth: `${this.item.style?.border_width}px`,
                borderStyle: this.item.style?.border_style,
                borderColor: this.item.style?.border_color,
                fontSize: `${this.style.font_size}px`,
                paddingTop: this.item.style?.border_width && this.item.style?.border_width !== 0 ? '1.4rem' : '0',
                paddingBottom: this.item.style?.border_width && this.item.style?.border_width !== 0 ? '0.525rem' : '0',
                paddingInline: this.item.style?.border_width && this.item.style?.border_width !== 0 ? '1rem' : '0',
            }
        },
        multipleChoiceAnswerFormat(): number {
            return this.item.data.settings?.format || parseInt(defaultLetteringFormat.value)
        },
    },
    async created() {
        EventBus.$emit('wordScrambleAnswerKeyWidth', this.answerKeyWidthAvailable)

        if (!this.item.data.term) return

        await this.$store.dispatch('document/updateWordScrambleLegacy', this.item.display_order)
    },
    methods: {
        scramble(word: string, seed: number): string {
            if (!seed) {
                seed = 1
            }

            word = word?.trim()
            if (!this.canBeShuffled(word)) {
                // can't scramble a short word!
                return word?.replace(/ /g, '_')
            }

            let newWord = word
            do {
                let c = new Chance(this.item.data.shuffle_seed + seed)
                const letters = word.split('')
                newWord = c.shuffle(letters).join('')
                seed++
            } while (newWord == word)
            if (!newWord) return ''

            if (this.item.data.text_case === 'capitalize') newWord = newWord.toLowerCase()

            return newWord.replace(/ /g, '_')
        },
        getWordScrambleNumbering(index: number): Numbering {
            const numbering = { ...this.item.numbering }
            const totalTerms = this.item.data.terms?.length
            const numberOfColumns =
                typeof this.item.data.number_of_columns === 'string'
                    ? parseInt(this.item.data.number_of_columns)
                    : this.item.data.number_of_columns

            if (!numbering.number || !totalTerms || !numberOfColumns)
                return {
                    format: 1,
                    number: 0,
                    hidden: true,
                    numbering_restarted: false,
                } as Numbering

            if (this.item.data.order_numbers_by === 'row' || totalTerms <= numberOfColumns) {
                numbering.number = numbering.number + index

                return numbering as Numbering
            }

            const row = Math.floor(index / numberOfColumns)
            const col = index % numberOfColumns
            const maxRows = Math.ceil(totalTerms / numberOfColumns)
            let rowBasedIndex = row + col * maxRows
            let correctNumbering = false

            if (numberOfColumns === 3) {
                correctNumbering = totalTerms % numberOfColumns === 1 && col === 2
            }

            if (numberOfColumns === 4) {
                if (totalTerms % numberOfColumns === 1) {
                    correctNumbering = [2, 3].includes(col)
                    if (col === 3) rowBasedIndex--
                }

                if (totalTerms % numberOfColumns === 2) {
                    correctNumbering = [3].includes(col)
                }
            }

            numbering.number =
                numbering.number +
                (rowBasedIndex < totalTerms ? rowBasedIndex + (correctNumbering ? -1 : 0) : totalTerms - 1)

            return numbering as Numbering
        },
        canSplitWord(word: string): boolean {
            if (!word) return false

            return word.trim().split(' ').length > 1
        },
    },
})
</script>

<style scoped>
.term-wrapper {
    flex-basis: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
}

.answer {
    position: relative;
    display: inline-flex;
    min-width: fit-content;
    justify-content: center;
}

.answer-border {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    justify-content: center;
    border-bottom: 2px solid black;
}

.word-scramble {
    column-gap: 0.35in;
}

.text-pre {
    white-space: nowrap;
    margin-left: 1px;
}

.preview-number {
    min-width: 20px;
}

.term {
    overflow: hidden;
}
</style>
