<template>
    <div
        :id="`preview-${itemData.id}`"
        :style="componentStyle"
        class="handwriting-practice w-auto last-item"
        @click.stop="clickHandler"
    >
        <div class="d-flex handwriting" :style="lineColorStyle" :class="{ 'red-blue': data.color_option !== 'solid' }">
            <div v-for="(partial, index) in partials" :key="'hw-partial-' + index" class="hw-practice">
                <div :style="inlineStyle(partial.style)" v-html="partial.text"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, CSSProperties, PropType } from 'vue'
import { isNumber } from 'lodash'
import { PartialStyle, HandWritingInputItem } from '../../types/handwritinginputitem'
import {
    DEFAULT_HW_FONT_SIZE,
    FONT_TYPE_DASHED,
    FONT_TYPE_REGULAR,
    FONT_TYPE_STROKED,
    FONT_TYPE_GUIDED,
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_STROCK,
    HANDWRITING_TYPE_TRACE,
    HANDWRITING_TYPE_GUIDE,
    handwritingFontSizes,
    STANDARD_PRINT,
    CLASSIC_CURSIVE,
    CLASSIC_PRINT,
    DNEALIAN_PRINT,
    HANDWRITING_LINE_HEIGHT_MIN,
    HANDWRITING_LINE_HEIGHT_MAX,
} from '../../store/helpers/documentHelpers'

interface Data {
    HANDWRITING_TYPE_SOLID: string
    HANDWRITING_TYPE_TRACE: string
    HANDWRITING_TYPE_STROCK: string
    upperMarginBound: number
    lowerMarginBound: number
    lineHeightBreakpoint: number
    lineHeightSpaceFactor: number
    clickCount: number
    itemData: HandWritingInputItem
}

export default defineComponent({
    name: 'HandwritingItemPreview',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        cursiveFont: {
            type: String,
            default: '',
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        lastItem: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            HANDWRITING_TYPE_SOLID,
            HANDWRITING_TYPE_TRACE,
            HANDWRITING_TYPE_STROCK,
            upperMarginBound: 18,
            lowerMarginBound: 14,
            lineHeightBreakpoint: 40,
            lineHeightSpaceFactor: 1.8,
            clickCount: 0,
            itemData: { ...this.item } as HandWritingInputItem,
        }
    },
    computed: {
        itemPartials(): PartialStyle[] {
            return this.itemData.partials
                ? Array.isArray(this.itemData.partials)
                    ? this.itemData.partials
                    : Object.values(this.itemData.partials)
                : []
        },
        partials(): { text: string; style: string }[] {
            return this.itemPartials
                ? this.itemPartials.map((p: PartialStyle) => ({
                      text: this.itemData.subtitle?.substring(p.start, p.end) || '',
                      style: p.style,
                  }))
                : []
        },
        hwFontScale(): number {
            return this.formattedLineHeight ? this.formattedLineHeight / DEFAULT_HW_FONT_SIZE : 1
        },
        formattedLineHeight(): number {
            return isNumber(this.data.line_height) ? this.data.line_height : parseFloat(this.data.line_height)
        },
        calculatedMarginBottom(): number {
            return (
                this.formattedLineHeight -
                (this.formattedLineHeight > this.lineHeightBreakpoint ? this.lowerMarginBound : this.upperMarginBound)
            )
        },
        calculatedMarginTop(): string {
            const lineHeight = this.data.line_height / 96 // inches to pixels
            const minLineHeight = HANDWRITING_LINE_HEIGHT_MIN // inches
            const maxLineHeight = HANDWRITING_LINE_HEIGHT_MAX // inches
            const minMargin = 0.2 // em
            const maxMargin = 0.8 // em

            // Calculate the percentage of the range
            const percentage = Math.min(Math.max((lineHeight - minLineHeight) / (maxLineHeight - minLineHeight), 0), 1)

            const margin = minMargin + (maxMargin - minMargin) * percentage

            return margin
        },
        componentStyle(): CSSProperties {
            let style: CSSProperties = {
                transformOrigin: 'left top',
                transform: `scale(${this.hwFontScale})`,
                marginTop: `${this.calculatedMarginTop}em`,
                marginBottom: `${this.calculatedMarginBottom}px`,
            }

            if (!this.lastItem) {
                style.paddingBottom = `${this.data.spacing + this.calculatedMarginBottom / this.lineHeightSpaceFactor}px`
            } else {
                style.paddingBottom = `calc(${this.calculatedMarginTop}em + ${this.calculatedMarginBottom / this.lineHeightSpaceFactor}px)`
            }

            return style
        },
        lineColorStyle(): CSSProperties {
            if (this.data.color_option !== 'solid') return {}

            return {
                borderColor: this.data.guide_line_color,
            }
        },
    },
    methods: {
        inlineStyle(style: string): CSSProperties {
            let styles: CSSProperties
            let cursiveFont: string = this.cursiveFont
            let styleName: string = ''
            let fontFamily: string = ''

            style = this.$fonts.getActiveHandwritingLineStyle(cursiveFont, style)

            if (this.$fonts.isHwFont(cursiveFont)) {
                if (style === HANDWRITING_TYPE_SOLID) {
                    styleName = FONT_TYPE_REGULAR
                } else if (style === HANDWRITING_TYPE_TRACE) {
                    styleName = FONT_TYPE_DASHED
                } else if (style === HANDWRITING_TYPE_GUIDE) {
                    styleName = FONT_TYPE_GUIDED
                } else {
                    styleName = FONT_TYPE_STROKED
                }

                let fontName: string

                if ([CLASSIC_CURSIVE, CLASSIC_PRINT, DNEALIAN_PRINT].includes(cursiveFont)) {
                    fontName = cursiveFont
                } else {
                    fontName = cursiveFont === STANDARD_PRINT ? 'Precursive' : 'Cursive'
                }

                fontFamily = `${fontName} ${styleName}`.trim()

                styles = {
                    fontFamily: `'${fontFamily}'`,
                    fontWeight: this.isBasicFont(cursiveFont) && this.isTraceable(style) ? 'bold' : 'normal',
                }
            } else {
                fontFamily = cursiveFont
                styles = {
                    fontFamily: `'${fontFamily}'`,
                }

                if (style === HANDWRITING_TYPE_TRACE) {
                    styles.opacity = 0.25
                }
            }

            let font = cursiveFont
            if (
                [CLASSIC_PRINT, DNEALIAN_PRINT].includes(cursiveFont) &&
                (style === HANDWRITING_TYPE_STROCK || style === HANDWRITING_TYPE_GUIDE)
            ) {
                font = `${cursiveFont} ${styleName}`.trim()
            }

            const fontKey = font as keyof typeof handwritingFontSizes
            if (handwritingFontSizes[fontKey]) {
                styles.fontSize = handwritingFontSizes[fontKey]
            }

            const transition = this.$fonts.getPlatformSpecificTransition(font)
            if (transition) {
                styles.transform = `translateY(${transition})`
            }

            return styles
        },
        isTraceable(style: string): boolean {
            return [HANDWRITING_TYPE_TRACE].includes(style)
        },
        isBasicFont(font: string): boolean {
            return font === STANDARD_PRINT
        },
        clickHandler() {
            this.$emit('optionItemClicked', {
                ...this.item,
                ...{ clickedIndex: { index: this.itemData.id, clickCount: ++this.clickCount } },
            })
        },
    },
})
</script>

<style lang="scss" scoped>
.handwriting p {
    color: black;
}
.hw-practice {
    white-space: pre;
}
</style>
