<template>
    <div class="d-flex break-all">
        <span class="pr-2">
            <span>{{ displayNo }}.</span>
        </span>
        <div class="flex-grow-1 mb-2">
            <p v-if="item.prompt" class="mb-1 break-word preserve-whitespace">
                <strong>Q:</strong>
                {{ item.definition }}
            </p>
            <div class="d-flex align-items-start">
                <div v-if="item.prompt" class="mr-1">
                    <strong>A:</strong>
                </div>
                <div v-if="bingoHasImage" class="mr-1">
                    <InlineImage
                        v-if="hasImage"
                        :image-id="imageId"
                        :size="wordbankStyle.font_size + 10"
                        :min-size="wordbankStyle.font_size"
                    />
                    <div
                        v-else-if="!item.prompt"
                        :class="{ invisible: bingoHasImage && !hasImage }"
                        :style="{
                            minWidth: `${wordbankStyle.font_size + 10}px`,
                            minHeight: `${wordbankStyle.font_size - 10}px`,
                        }"
                    ></div>
                </div>
                <div :class="termClass" class="break-word preserve-whitespace">
                    {{ item.term }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InlineImagesMixin from '../mixins/InlineImages'
import ImagesMixin from '../mixins/Images'
import InlineImage from './widgets/InlineImage.vue'

export default {
    name: 'CallListItem',
    components: {
        InlineImage,
    },
    mixins: [ImagesMixin, InlineImagesMixin],
    props: {
        displayNo: {
            type: Number,
            default: 0,
        },
        item: {
            type: Object,
            required: true,
        },
        imageId: {
            type: String,
            required: false,
            default: null,
        },
        bingoHasImage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            wordbankStyle: 'document/wordbankStyle',
        }),
        hasImage() {
            return this.url?.length > 0 ?? false
        },
        properties() {
            return this.getInlineImage(this.imageId)
        },
        shouldLoadSvg() {
            return this.isSvg
        },
        termClass() {
            return [this.hasImage ? `mb-n1` : `mb-0`]
        },
    },
    watch: {
        'properties.color': {
            handler() {
                if (this.isSvg) this.updateColor()
            },
        },
        'properties.objectId': {
            async handler(newValue, oldValue) {
                if (newValue !== oldValue) await this.loadImage()
            },
        },
    },
    async mounted() {
        if (!this.isQueueWorker) {
            await this.loadImage()
        }
    },
}
</script>

<style lang="scss" scoped>
.preloader-spinner,
.preloader {
    max-width: 50px;
    max-height: 50px;
}
.break-word {
    word-break: break-word;
}
</style>
