<template>
    <div v-if="!item.hide">
        <OnPageIndicator v-if="showOnPageIndicator" />
        <div
            v-else
            class="d-block mb-0 basic-text preserve-whitespace"
            :style="{
                minHeight: style.font_space + 'em', //lineheight
                boxSizing: 'content-box',
            }"
            v-html="item.data.instruction"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import OnPageIndicator from '../OnPageIndicator.vue'

export default defineComponent({
    name: 'BasicTextPreview',
    components: {
        OnPageIndicator,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        showOnPageIndicator(): boolean {
            return !Boolean(this.item.data.instruction)
        },
    },
})
</script>
